import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewGameComponent } from './components/new-game/new-game.component';
import { HandAddEditComponent } from './components/hand-add-edit/hand-add-edit.component';
import { GameSummaryComponent } from './components/game-summary/game-summary.component';
import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
  { path: 'newgame', redirectTo: 'newgame/new', pathMatch: 'full' },
  { path: 'newgame/:gameid', component: NewGameComponent },
  { path: 'hand/:gameid/:handid', component: HandAddEditComponent },
  { path: 'summary/:gameid', component: GameSummaryComponent },
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
