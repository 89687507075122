import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { Game, Player } from 'src/app/data/entities';
import { DataService } from 'src/app/services/data.service';
import { FirebaseDataService } from 'src/app/services/firebase-data.service';

@Component({
  selector: 'gom-game-summary',
  templateUrl: './game-summary.component.html',
  styleUrls: ['./game-summary.component.css']
})
export class GameSummaryComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dataSvc: DataService,
    private router: Router,
    private fbSvc: FirebaseDataService
    ) {}

  gameID: string;
  game: Game;

  ngOnInit() {
    // Get game id from route
    this.route.paramMap.subscribe(p => {
      this.gameID = p.get('gameid');

      // Now get game object
      // this.game = this.dataSvc.games.find(a => a.ID === this.gameID);
      this.fbSvc.getGame(this.gameID)
      .subscribe(res => {
        this.game = res;

        if (!this.game) {
          this.router.navigate(['/newgame'], { replaceUrl: true });
        }
      });
    });
  }

  editGame() {
    this.router.navigate(['/newgame/' + this.gameID], { replaceUrl: true });
  }

  showHandDetails(handid: number) {
    this.router.navigate([`/hand/${this.gameID}/${handid}`], { replaceUrl: true });
  }

  getPlayerTotal(player: Player): number {
    let total = 0;
    this.game.HaatPlayed.forEach(a => {
      total += a.PlayerHaatDetails.find(phd => phd.Player.ID === player.ID).PointTotal;
    });

    return total;
  }

  getPlayerTotalConverted(player: Player): number {
    let total = 0;
    this.game.HaatPlayed.forEach(a => {
      total += a.PlayerHaatDetails.find(phd => phd.Player.ID === player.ID).PointTotal;
    });

    return Math.round(total * this.game.PointConversionFactor * 100) / 100;
  }

  newHand() {
    this.router.navigate([`/hand/${this.gameID}/new`], { replaceUrl: true });
  }
}
