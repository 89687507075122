import { Injectable } from '@angular/core';
import { Haat, Game } from '../data/entities';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  constructor() { }

  public calculateHaat(hand: Haat, game: Game) {
    // Check minimum required entries before calculating
    // Get winner player
    let winnerDetails = hand.PlayerHaatDetails.find(a => a.Player.ID === hand.WinnerID);

    if (!winnerDetails) {
      return;
    }

    // Get total maal
    let totalMaal = 0;
    hand.PlayerHaatDetails.forEach(a => totalMaal += a.MaalCount);

    // Calculate everyone else
    hand.PlayerHaatDetails.filter(a => a.Player.ID !== hand.WinnerID).forEach(phd => {
      const receive = phd.MaalCount * game.NumberOfPlayers;
      let pay = totalMaal;
      // Now pay points
      if (!phd.Dublee) {
        pay += phd.Seen ? game.SeenPointCount : game.UnseenPointCount;
      }

      phd.PointTotal = receive - pay;
    });

    // Now calculate winner
    let winnerPoints = 0;
    hand.PlayerHaatDetails
      .filter(a => a.Player.ID !== hand.WinnerID)
      .forEach(a => winnerPoints += a.PointTotal);

    winnerDetails.PointTotal = -1 * winnerPoints;
  }

  deepCopy(obj: any) {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
              copy[attr] = this.deepCopy(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
}

}
