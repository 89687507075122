import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Game } from 'src/app/data/entities';
import { DataService } from 'src/app/services/data.service';
import { FirebaseDataService } from 'src/app/services/firebase-data.service';

@Component({
  selector: 'gom-new-game',
  templateUrl: './new-game.component.html',
  styleUrls: ['./new-game.component.css']
})
export class NewGameComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataSvc: DataService,
    private fbSvc: FirebaseDataService
  ) {
    this.playerCountNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.seenPointCountNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    this.unseenPointCountNumbers = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  }

  gameID: string;
  game: Game;
  isNewGame: boolean;
  playerCountNumbers: number[];
  seenPointCountNumbers: number[];
  unseenPointCountNumbers: number[];

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      this.gameID = p.get('gameid');

      this.isNewGame = !this.gameID || this.gameID.toLowerCase() === 'new';

      if (this.isNewGame) {
      this.game = {
        NumberOfPlayers: 5,
        Players: [],
        UnseenPointCount: 10,
        SeenPointCount: 3,
        PointConversionFactor: 0.25,
        HaatPlayed: []
      };

      this.numberOfPlayersUpdate();

    } else {
      this.fbSvc.getGame(this.gameID).subscribe(res => {
        this.game = res;
      });
    }
    });
  }

  numberOfPlayersUpdate() {
    if (this.game.NumberOfPlayers > this.game.Players.length) {
      // Keep adding players
      while (this.game.Players.length < this.game.NumberOfPlayers) {
        this.game.Players.push({ID: this.game.Players.length + 1, Name: `Player ${this.game.Players.length + 1}`});
      }
    } else {
      // Trim players array to match
      this.game.Players.splice(this.game.NumberOfPlayers);
    }
  }

  startGame() {
    // this.dataSvc.games.push(this.game);
    if (this.isNewGame) {
      this.gameID = Guid.newGuid();

      this.fbSvc.createNewGame(this.gameID, this.game);
    } else {
      this.fbSvc.saveGame(this.gameID, this.game);
    }

    this.router.navigate([`summary/${this.gameID}`], { replaceUrl: true });
  }

  cancel() {
    if (this.isNewGame) {
      this.router.navigate([''], { replaceUrl: true });
    } else {
      this.router.navigate([`summary/${this.gameID}`], { replaceUrl: true });
    }
  }
}

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
