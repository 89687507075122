import { Component, OnInit } from '@angular/core';
import { Haat, HaatDetails, Game } from 'src/app/data/entities';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { WorkerService } from 'src/app/services/worker.service';
import { FirebaseDataService } from 'src/app/services/firebase-data.service';

@Component({
  selector: 'gom-hand-add-edit',
  templateUrl: './hand-add-edit.component.html',
  styleUrls: ['./hand-add-edit.component.css']
})
export class HandAddEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dataSvc: DataService,
    private router: Router,
    private wkSvc: WorkerService,
    private fbSvc: FirebaseDataService
  ) {
    this.numbers = new Array(100).fill(1).map((x, i) => i).filter(a => a !== 1);
  }

  gameid: string;
  handid: string;

  game: Game;
  hand: Haat;

  numbers: number[];

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      this.gameid = p.get('gameid');
      this.handid = p.get('handid');

      // Now get haat object
      // this.game = this.dataSvc.games.find(a => a.ID === this.gameid);
      this.fbSvc.getGame(this.gameid)
      .subscribe(res => {
        this.game = res;

        // Setup hand
        if (this.game) {
          this.setupHand();
        } else {
          this.router.navigate(['/newgame'], { replaceUrl: true });
        }
      });
    });
  }

  private setupHand() {
    if (this.handid === 'new') {
      // New hand
      // Default player haat details with items equal to number of items
      const phds: HaatDetails[] = [];
      this.game.Players.forEach(pl => {
        // Setup player haat
        const phd = {
          Player: pl,
          Seen: false,
          Dublee: false,
          MaalCount: 0,
          PointTotal: 0
        };
        phds.push(phd);
      });

      // Create hand
      const id = this.game.HaatPlayed.length + 1;
      this.hand = { ID: id, WinnerID: -1, PlayerHaatDetails: phds };
    } else {
      // Load hand
      this.hand = this.game.HaatPlayed.find(a => a.ID === +this.handid);
    }
  }

  winnerChange() {
    const winner = this.hand.PlayerHaatDetails.find(a => a.Player.ID === this.hand.WinnerID);

    // Winner is always seen = true
    winner.Seen = true;

    // Winner's dublee doesn't matter
    winner.Dublee = false;

    this.calculate();
  }

  seenChange(phd: HaatDetails) {
    if (phd.Seen === false) {
      phd.MaalCount = 0;
      phd.Dublee = false;
    }

    this.calculate();
  }

  maalCountChange() {
    this.calculate();
  }

  dubleeChange() {
    this.calculate();
  }

  seenEnabled(phd: HaatDetails): boolean {
    // Disabled for winner
    return phd.Player.ID !== this.hand.WinnerID;
  }

  maalCountEnabled(phd: HaatDetails): boolean {
    return phd.Seen;
  }

  dubleeEnabled(phd: HaatDetails): boolean {
    if (phd.Player.ID === this.hand.WinnerID) {
      return false;
    } else {
      return phd.Seen;
    }
  }

  calculate() {
    this.wkSvc.calculateHaat(this.hand, this.game);
  }

  get toatlMaal(): number {
    let tot = 0;
    this.hand.PlayerHaatDetails.forEach(a => tot += a.MaalCount);

    return tot;
  }

  saveHaat() {
    if (this.handid === 'new') {
      this.game.HaatPlayed.push(this.hand);
    }

    // Calculate haat before going to summary
    this.calculate();

    this.fbSvc.saveGame(this.gameid, this.game);

    this.returnToSummary();
  }

  cancelChanges() {
    this.returnToSummary();
  }

  returnToSummary() {
    this.router.navigate([`/summary/${this.gameid}`], { replaceUrl: true });
  }
}
