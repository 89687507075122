import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Game } from '../data/entities';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  gameCollection: AngularFirestoreCollection;
  gameDocument: AngularFirestoreDocument;

  constructor(private firestore: AngularFirestore) {
    this.gameCollection = this.firestore.collection('games');
   }

  createNewGame(id: string, game: Game) {
    this.gameCollection.doc(id).set(game);
  }

  getGame(id: string) {
    return this.gameCollection.doc<Game>(id).valueChanges();
  }

  saveGame(id: string, game: Game) {
    this.gameCollection.doc(id).set(game);
  }
}
